import BackofficeOperationDataTable from '../utils/BackofficeOperationDataTable';

export default class BackofficeStudyRequestsIndex {
  constructor() {
    this.dataTable = new BackofficeOperationDataTable("#sr-datatable");
  }

  onDataChanged(data) {
    // Custom logic for study requests
  }

  onDestroy() {
    this.dataTable.onDestroy();
  }
}