import DraggableTable from '../utils/DraggableTable';

export default class BackofficeWorkflowsShow extends DraggableTable {
  constructor() {
    super('draggable-tbody'); // Call the parent class's constructor with the tableId
    this.bindEvents();
  }

  bindEvents() {
    $(".toggle-step").off('change', this.toggleStep).on('change', this.toggleStep);

    $("#bo-preview").on('click', this.loadPreview.bind(this));
    $("#extranet-preview").on('click', this.loadPreview.bind(this));

    $("#team-select").on('change', this.loadPreview.bind(this));
    $("#entity-select").on('change', this.loadPreview.bind(this));

    $("#bo-preview").trigger('click');
  }

  updateOrder() {
    const self = this;
    
    const rows = document.querySelectorAll(`#${this.tableId} tr`);
    const ids = Array.from(rows).map(row => row.getAttribute('data-id'));
    const url = $("#draggable-tbody").data('url');

    self.rearrangeRowsOrder(ids);

    $.ajax({
      url: url,
      method: 'PUT',
      data: { steps_ids: ids },
      error: function() {
        application.errorNotification(I18n.t("backoffice.workflows.update.error"))
      },
      success: function(data){
        if (!data.valid_order){
          $("#order-warning").removeClass("d-none");
        }else{
          $("#order-warning").addClass("d-none");
        }
      }
    });
  }

  rearrangeRowsOrder(ids) {
    // for each table row, change the second td content to the new position
    const rows = $(`#${this.tableId} tr`);
    rows.each((index, row) => {
      $(row).find('td:nth-child(2)').text(index + 1);
    });
  }

  toggleStep(e) {
    const checkbox = e.target;
    const url = checkbox.dataset.url;
    const checked = checkbox.checked;
    
    $.ajax({
      url: url,
      method: 'PUT',
      data: { active: checked },
      success: function() {
        application.successNotification("Étape mise à jour avec succès")
      },
      error: function() {
        application.errorNotification("Erreur lors de la mise à jour de l'étape")
      }
    });
  }

  loadPreview(e) {
    e.preventDefault();
    const url = $(e.target).data('url');

    var is_bo = $(e.target).data('type') == 'backoffice';

    if (is_bo) {
      $("#bo-preview").addClass('underline');
      $("#extranet-preview").removeClass('underline');

      $("#teams-select-wrapper").show();
      $("#entities-select-wrapper").hide();
    } else {
      $("#extranet-preview").addClass('underline');
      $("#bo-preview").removeClass('underline');

      $("#teams-select-wrapper").hide();
      $("#entities-select-wrapper").show();
    }

    var data = {};
    if (is_bo) {
      data = { team_id: $("#team-select").val() }
    }else{
      data = { entity_id: $("#entity-select").val() }
    }

    $.ajax({
      url: url,
      method: 'GET',
      data: data,
      success: function(data) {
        $("#preview-content").html(data);
      },
      error: function() {
        application.errorNotification("Erreur lors du chargement de l'aperçu")
      }
    });
  }


  onDataChanged(data) {

  }

  onDestroy() {

  }
}