import BackofficeOperationDataTable from '../utils/BackofficeOperationDataTable';

export default class BackofficeOrdersIndex {
  constructor() {
    this.dataTable = new BackofficeOperationDataTable("#orders-datatable");
  }

  onDataChanged(data) {
    // Custom logic for orders
  }

  onDestroy() {
    this.dataTable.onDestroy();
  }
}