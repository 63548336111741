export default class BackofficeOrdersNew {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    this.initializeSelect2();
    this.setupFormValidation();
    this.setupInputChangeListener();
  }

  initializeSelect2() {
    let $select2 = $("#options_select");
    $select2.select2({
      multiple: true,
      closeOnSelect: false
    });
    $select2.val("");
    $select2.trigger("change");
    $select2.change(this.onOptionChanged);
  }

  setupFormValidation() {
    $("#order-form-submit").on('click', (event) => {
      this.validateFormFields();
      const emailValid = this.validateEmail();
      const phoneValid = this.validatePhone();
      const collectGateValid = this.validateCollectGate();

      if (!this.isFormValid(emailValid, phoneValid, collectGateValid)) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }

      return true;
    });
  }

  validateFormFields() {
    $("#order-form input, #order-form select, #order-form textarea").each(function() {
      if (!$(this).prop('readonly')) {
        if (!this.checkValidity()) {
          $(this).addClass('is-invalid');
          if ($(this).next('.invalid-feedback').length === 0) {
            $(this).after('<div class="invalid-feedback">Ce champ est obligatoire.</div>');
          }
        } else {
          $(this).removeClass('is-invalid').addClass('is-valid');
          $(this).next('.invalid-feedback').remove();
        }
      }
    });
  }

  validateEmail() {
    let email = $("#customer_contact_email").val();
    let emailValid = !email || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    if (!emailValid) {
      this.showErrorMessage('customer_contact_email', 'Merci de saisir une adresse email valide.');
    } else {
      this.hideErrorMessage('customer_contact_email');
    }

    return emailValid;
  }

  validatePhone() {
    let phone = $("#customer_contact_phone").val();
    let phoneValid = !phone || /^(\+33|0)[1-9](\d{2}){4}$/.test(phone);

    if (!phoneValid) {
      this.showErrorMessage('customer_contact_phone', 'Merci de saisir un numéro de téléphone valide.');
    } else {
      this.hideErrorMessage('customer_contact_phone');
    }

    return phoneValid;
  }

  validateCollectGate() {
    let collectGate = $("#collect_gate_a").val();
    if (!collectGate) {
      this.showErrorMessage('collect_gate_a', 'Merci de choisir une porte de collecte.');
      return false;
    } else {
      this.hideErrorMessage('collect_gate_a');
      return true;
    }
  }

  isFormValid(emailValid, phoneValid, collectGateValid) {
    return $("#order-form")[0].checkValidity() && emailValid && phoneValid && collectGateValid;
  }

  setupInputChangeListener() {
    $("#order-form input, #order-form select, #order-form textarea").on('input change', function() {
      $(this).removeClass('is-invalid is-valid');
      $(this).next('.invalid-feedback').remove();
    });
  }

  showErrorMessage(fieldId, message) {
    const field = $(`#${fieldId}`);
    field.addClass('is-invalid');
    if (field.next('.invalid-feedback').length === 0) {
      field.after(`<div class="invalid-feedback">${message}</div>`);
    }
  }

  hideErrorMessage(fieldId) {
    const field = $(`#${fieldId}`);
    field.removeClass('is-invalid');
    field.next('.invalid-feedback').remove();
  }

  onOptionChanged(e) {
    let options = $("#options_select option:selected");
    let fas = 0;
    let subscription = 0;

    options.each(function() {
      fas += parseFloat($(this).data("fas"));
      subscription += parseFloat($(this).data("subscription"));
    });

    this.updateOptionTotals(fas, subscription);
  }

  updateOptionTotals(fas, subscription) {
    $("#fas_options").text(I18n.toCurrency(fas));
    $("#subscription_options").text(I18n.toCurrency(subscription));

    let fas_total = parseFloat($("#fas_product").data("price")) + fas;
    let subscription_total = parseFloat($("#subscription_product").data("price")) + subscription;

    $("#fas_total").text(I18n.toCurrency(fas_total));
    $("#subscription_total").text(I18n.toCurrency(subscription_total));
  }

  onDataChanged(data) {
    // Implementation for data change handling
  }

  onDestroy() {
    $("#options_select").select2("destroy");
  }
}